/* Importaciones de Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9fafb;
}

/* Sobrescribe la altura de html y body */
html, body {
  height: auto;
  min-height: 100%;
}

/* Estilos para el scroll global */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #2745F8;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2745F8; /* Un tono más oscuro para el hover */
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #2745F8 #ffffff;
}

/* Clases de utilidad para scrollbars específicos */
.dark-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #2745F8 #ffffff;
}

.dark-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.dark-scrollbar::-webkit-scrollbar-track {
  background: #ffffff;
}

.dark-scrollbar::-webkit-scrollbar-thumb {
  background: #2745F8;
  border-radius: 4px;
}

.dark-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #2745F8;
}

/* Scrollbar más delgado para elementos específicos */
.thin-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #2745F8 transparent;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.thin-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background: #2745F8;
  border-radius: 3px;
}

.thin-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #2745F8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Estilos personalizados */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Animaciones para UI del chat */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  70% {
    transform: scale(1.03);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(177, 104, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 12px rgba(177, 104, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(177, 104, 255, 0);
  }
}

/* Animación del cursor parpadeante */
@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.animate-fade-in-up {
  animation: fadeInUp 0.4s ease-out forwards;
}

.animate-pop-in {
  animation: popIn 0.4s ease-out forwards;
}

.animate-fade-in {
  animation: fadeIn 0.5s ease-out forwards;
}

.animate-pulse-custom {
  animation: pulse 2s infinite;
}

/* Estilos mejorados para burbujas de chat */
.user-bubble {
  background: linear-gradient(135deg, #EFF6FF 0%, #DBEAFE 100%);
  border-right: 3px solid #3B82F6;
  border-radius: 18px 18px 2px 18px;
  box-shadow: 0 4px 15px rgba(59, 130, 246, 0.12);
  animation: fadeInUp 0.3s ease-out forwards;
  margin-bottom: 16px;
  max-width: 85%;
}

.assistant-bubble {
  background: white;
  border-left: 3px solid #974FF8;
  border-radius: 18px 18px 18px 2px;
  box-shadow: 0 4px 15px rgba(177, 104, 255, 0.12);
  animation: fadeInUp 0.3s ease-out forwards;
  margin-bottom: 16px;
  max-width: 85%;
}

/* Estilizado de contenido de mensajes */
.message-content p {
  margin-bottom: 0.75rem;
  line-height: 1.6;
}

.message-content strong {
  color: #4B5563;
  font-weight: 600;
}

.message-content h3, .message-content h4 {
  margin-top: 1rem;
  margin-bottom: 0.75rem;
  color: #1F2937;
}

/* Efectos de hover para botones de mensajes rápidos */
.quick-message-btn {
  transition: all 0.3s ease;
  height: 5rem;
}

.quick-message-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(177, 104, 255, 0.15);
}

.quick-message-btn:active {
  transform: translateY(-1px);
}

/* Animación del indicador de escritura */
.typing-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #2745F8;
  margin-right: 4px;
  animation: typing-dot 1.4s infinite ease-in-out both;
}

.typing-dot:nth-child(1) {
  animation-delay: -0.32s;
}

.typing-dot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes typing-dot {
  0%, 80%, 100% { 
    transform: scale(0.7);
    opacity: 0.5;
  }
  40% { 
    transform: scale(1);
    opacity: 1;
  }
}

/* Ocultar scrollbar para contenedores específicos */
.quick-messages-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: auto;
  padding-bottom: 4px;
}

.quick-messages-scroll::-webkit-scrollbar {
  display: none;
}

/* Estilos del área de chat */
.chat-container {
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
  position: relative; /* Importante para posicionamiento correcto */
}

.chat-header {
  padding: 16px;
  background-color: white;
  border-bottom: 1px solid #E5E7EB;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-messages-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
  position: relative; /* Para mantener scroll correcto */
}

.chat-input-container {
  border-top: 1px solid #E5E7EB;
  padding: 12px 16px;
  background-color: white;
  position: relative; /* Para evitar solapamiento */
  z-index: 10; /* Asegurar que esté sobre los mensajes */
}

/* Estilo para el input de chat */
.chat-input {
  transition: all 0.2s ease;
  border: 1px solid #E5E7EB;
  border-radius: 12px;
  overflow: hidden;
}

.chat-input:focus-within {
  border-color: #974FF8;
  box-shadow: 0 0 0 3px rgba(151, 79, 248, 0.2);
}

/* Estilos para el contenedor de thinking process */
.thinking-content {
  max-height: 200px;
  overflow-y: auto;
  border-radius: 8px;
  background-color: #F9FAFB;
  padding: 12px;
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 0.875rem;
  line-height: 1.5;
}

.thinking-content::-webkit-scrollbar {
  width: 4px;
}

.thinking-content::-webkit-scrollbar-thumb {
  background: #2745F8;
  border-radius: 10px;
}

/* Estilos para los botones de consultas rápidas */
.quick-buttons-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  margin-bottom: 16px;
}

@media (min-width: 640px) {
  .quick-buttons-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

.quick-button {
  background-color: white;
  border: 1px solid #E5E7EB;
  border-radius: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px; /* Reducido para mejor visualización */
  transition: all 0.2s ease;
  cursor: pointer;
}

@media (min-width: 640px) {
  .quick-button {
    height: 100px;
  }
}

.quick-button:hover {
  background-color: #F9FAFB;
  border-color: #2745F8;
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(151, 79, 248, 0.1);
}

.quick-button-icon {
  font-size: 24px;
  margin-bottom: 8px;
}

.quick-button-text {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #4B5563;
}

/* Soporte para tablas en mensajes de chat */
.message-table {
  width: 100%;
  border-collapse: collapse;
  margin: 16px 0;
  font-size: 0.875rem;
  overflow-x: auto;
  display: block;
}

.message-table th {
  background-color: #F3F4F6;
  color: #374151;
  font-weight: 600;
  text-align: left;
  padding: 10px;
  border: 1px solid #E5E7EB;
}

.message-table td {
  padding: 10px;
  border: 1px solid #E5E7EB;
  color: #4B5563;
}

.message-table tr:nth-child(even) {
  background-color: #F9FAFB;
}

/* Utilidades para manejo de contenido responsivo */
.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Z-index para controlar capas */
.z-chat-interface { z-index: 10; }
.z-input-area { z-index: 20; }
.z-navigation { z-index: 30; }
.z-toast { z-index: 50; }

/* Tamaño de texto muy pequeño */
.text-xxs {
  font-size: 0.625rem;
  line-height: 0.75rem;
}

/* Mejoras para contenedor de mensajes rápidos */
.quick-messages-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  position: relative; /* Para scroll en móvil */
}

@media (min-width: 640px) {
  .quick-messages-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 0.75rem;
  }
}

/* Cuando hay muchas opciones, permitir scroll vertical */
.quick-messages-expanded {
  max-height: 180px;
  overflow-y: auto;
  padding-right: 0.25rem;
}

.quick-messages-expanded::-webkit-scrollbar {
  width: 3px;
}

.quick-messages-expanded::-webkit-scrollbar-track {
  background: transparent;
}

.quick-messages-expanded::-webkit-scrollbar-thumb {
  background: #2745F8;
  border-radius: 3px;
}

/* Prevenir solapamiento con la navegación inferior */
.pb-safe {
  padding-bottom: env(safe-area-inset-bottom, 60px);
}

/* Media queries para adaptación móvil */
@media (max-width: 640px) {
  .user-bubble, .assistant-bubble {
    max-width: 90%;
    padding: 12px;
    margin-bottom: 10px; /* Reducir spacing */
  }
  
  .quick-button {
    height: 72px; /* Más compacto en móvil */
    padding: 8px;
  }
  
  .quick-button-icon {
    font-size: 18px;
    margin-bottom: 4px;
  }
  
  .quick-button-text {
    font-size: 11px;
  }
  
  .chat-header {
    padding: 10px;
  }
  
  .chat-messages-container {
    padding: 10px;
  }
  
  .chat-input-container {
    padding: 8px 10px;
  }
  
  .message-content p,
  .message-content div {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
  }
  
  .thinking-content {
    max-height: 120px;
    padding: 8px;
    font-size: 0.75rem;
  }
  
  /* Fix específico para el mensaje de bienvenida */
  .welcome-screen {
    padding: 12px !important;
  }
  
  /* Ocultar elementos innecesarios en móvil */
  .hidden.sm\\:block {
    display: none !important;
    visibility: hidden;
    position: absolute;
    opacity: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    white-space: nowrap;
  }
  
  /* Asegurar que el contenido principal tiene suficiente padding */
  .main-content-with-tabs {
    padding-bottom: 68px !important; /* Asegurar espacio para navbar */
  }
  
  /* Ajustar tamaño y espaciado para botones rápidos */
  .quick-message-btn {
    height: 4rem;
    padding: 0.5rem;
  }
}

/* Fix for PDF export - ensure charts render properly even when hidden */
@media print {
  .hidden.sm\\:block {
    display: block !important;
    visibility: visible;
    position: static;
    opacity: 1;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
    clip-path: none;
    white-space: normal;
  }
}

/* Estilos para DatePicker */
.react-datepicker {
  font-family: 'Inter', system-ui, -apple-system, sans-serif;
  border-radius: 1rem !important;
  border: 1px solid #e5e7eb !important;
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  padding: 1rem !important;
  background-color: white !important;
}

.react-datepicker__header {
  background-color: white !important;
  border-bottom: none !important;
  padding-top: 0.5rem !important;
  font-size: 1rem !important;
}

.react-datepicker__current-month {
  color: #1f2937 !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  padding-bottom: 0.75rem !important;
}

.react-datepicker__navigation {
  top: 1rem !important;
}

.react-datepicker__navigation--previous {
  left: 1rem !important;
}

.react-datepicker__navigation--next {
  right: 1rem !important;
}

.react-datepicker__month {
  margin: 0.5rem 0 0 0 !important;
}

.react-datepicker__day-name {
  color: #6b7280 !important;
  font-weight: 500 !important;
  font-size: 0.8rem !important;
  margin: 0.4rem !important;
  width: 2rem !important;
  line-height: 2rem !important;
}

.react-datepicker__day {
  margin: 0.4rem !important;
  width: 2rem !important;
  line-height: 2rem !important;
  border-radius: 9999px !important;
  color: #4b5563 !important;
  font-size: 0.875rem !important;
}

.react-datepicker__day:hover {
  background-color: #f3f4f6 !important;
  border-radius: 9999px !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #2745F8 !important;
  color: white !important;
  font-weight: 600 !important;
}

.react-datepicker__day--selected:hover {
  background-color: #2745F8 !important;
}

.react-datepicker__day--outside-month {
  color: #d1d5db !important;
}

.react-datepicker__day--disabled {
  color: #d1d5db !important;
  cursor: not-allowed !important;
}

.react-datepicker__triangle {
  display: none !important;
}

/* Animación para el calendario */
@keyframes calendarFadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.react-datepicker-popper {
  z-index: 40 !important;
  animation: calendarFadeIn 0.2s ease-out !important;
}

/* Estilos para el modo móvil - DatePicker */
@media (max-width: 640px) {
  .react-datepicker {
    width: calc(100vw - 2rem) !important;
    max-width: 320px !important;
  }
  
  .react-datepicker__day, 
  .react-datepicker__day-name {
    width: 1.8rem !important;
    line-height: 1.8rem !important;
    margin: 0.2rem !important;
  }
}

/* Ajustes específicos para altura y posicionamiento en móvil */
@media (max-width: 640px) {
  .mobile-full-height {
    min-height: calc(100vh - 120px); /* Ajustar según header y footer */
  }
  
  .mobile-bottom-spacing {
    padding-bottom: 60px; /* Espacio para navegación inferior */
  }
  
  .mobile-fixed-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1);
    z-index: 30; /* Asegurar que esté por encima de otros elementos */
  }
  
  /* Solución para el corte con sección inferior */
  .chat-interface-wrapper {
    padding-bottom: 70px; /* Espacio para evitar solapamiento */
    height: calc(100% - 70px); /* Ajustar altura total */
  }
}